<template>
<div>
  <div class="outer sub" id="wrapper">
    <div class="container-fluid">
      <div class="row" style="padding-top: 125px">
        <div
          class="col-md-4 col-lg-4 col-xl-4 offset-col-md-1 offset-lg-1 offset-xl-1"
          data-aos="fade-up"
          data-aos-duration="750"
        >
          <h2>Menu</h2>

          <div class="item">
            <h3>{{ $t("menu.three.title") }}</h3>
            <p>{{ $t("menu.three.content") }}</p>
          </div>
          <div class="item">
            <h3>{{ $t("menu.four.title") }}</h3>
            <p>{{ $t("menu.four.content") }}</p>
          </div>
          <div class="item">
            <h3>{{ $t("menu.five.title") }}</h3>
            <p>{{ $t("menu.five.content") }}</p>
          </div>
          <div class="item">
            <h3>{{ $t("menu.ala.title") }}</h3>
            <p>{{ $t("menu.ala.content") }}</p>
          </div>
          <div class="item">
            <p>{{ $t("menu.onemenu") }}</p>
          </div>
          <div class="item">
            <p>{{ $t("menu.exceptions.line1")}}<br/>
            {{ $t("menu.exceptions.line2")}}<br/>
            {{ $t("menu.exceptions.line3")}}<br/>
            </p>
            <p>{{ $t("menu.exceptions.line4")}}</p>
          </div>  

        </div>

        <div
          class="col-md-6 offset-md-6 col-lg-6 offset-lg-0"
          v-scrollanimation
        >
          <img src="@/assets/img/Modestmenujuni-74.jpeg" class="img-fluid" />
        </div>
      </div>

    </div>

  </div>

  <div class="index outer bgblack" style="padding-top: 75px">
    <div class="container-fluid">

      <div class="row">

        <div
          class="col-md-6 col-lg-6"
        >
          <img src="@/assets/img/Modestmenujan2024-34.jpeg" class="img-fluid" />
        </div>
        <div
          class="col-md-6 offset-md-6 col-lg-6 offset-lg-0"
        >
          <img src="@/assets/img/Modestmenuaugustus2023-32.jpeg" class="img-fluid" />
        </div>

      </div>

      <div class="row" style="padding-top: 50px">

        <div
          class="col-md-6 col-lg-6"
        >
          <img src="@/assets/img/Modestmaart2024-60.jpeg" class="img-fluid" />
        </div>
        <div
          class="col-md-6 offset-md-6 col-lg-6 offset-lg-0"
        >
          <img src="@/assets/img/Modestmenuaugustus2023-69.jpeg" class="img-fluid" />
        </div>

      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "BodyMenu",
};
</script>

<style scoped>
#h2 {
  text-align: left;
  padding-left: 0;
}
</style>
