<template>
  <div class="outer bgblack footer">
    <div class="container-fluid" v-scrollanimation>
      <div class="row">
        <div class="col-12 col-lg-5 col-xl-6">
          <h4><img src="@/assets/img/logo-white.svg" /></h4>
        </div>
        <div class="col-lg-7 col-xl-6">
          <div class="row">
            <div class="col-12 col-md-4">
              <h5>{{ $t("practical.hours.title") }}</h5>
              <p>{{ $t("practical.hours.days") }}<br/>{{ $t("practical.hours.lunch") }}<br/>{{ $t("practical.hours.dinner") }}<br/>
              </p>
              <p>
                {{ $t("practical.hours.closed") }}
                </p>
            </div>
            <div class="col-12 col-md-4">
              <h5>Contact</h5>
              <p>
                <a href="mailto:info@restaurantmodest.be"
                  >{{ $t("footer.newsletter") }} </a
                ><br /><a href="tel:089/216020">089/216020</a>
              </p>
            </div>
            <div class="col-12 col-md-4">
              <!-- <h5>{{ $t("footer.follow") }}</h5> -->
              <a href="https://www.facebook.com/Restaurantmodest" target="_blank"
                ><img src="@/assets/img/icn_fb.svg" /></a
              ><a href="https://www.instagram.com/restaurantmodest/" target="_blank"
                ><img src="@/assets/img/icn_ig.svg"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>
