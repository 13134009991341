<template>
  <div class="home">
    <Header v-bind:isWhite="true" />
    <RestaurantModest />
    <Kennismaking />
    <WaaromModest />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import RestaurantModest from "@/components/RestaurantModest.vue";
import Kennismaking from "@/components/Kennismaking.vue";
import WaaromModest from "@/components/WaaromModest.vue";

export default {
  name: "Home",
  components: {
    Header,
    Footer,
    Kennismaking,
    RestaurantModest,
    WaaromModest
  }
};
</script>
