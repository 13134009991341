<template>
  <div
    v-if="isWhite"
    class="outer"
    id="header"
    v-bind:class="{ padding: isHeaderClass }"
  >
    <div class="container-fluid">
      <div class="row">
        <div class="col-6 nav-responsive">
          <ul
            class="navlist navl"
            v-bind:class="[isWhiteClass ? 'white' : 'black']"
          >
            <li>
              <router-link :to="`/${$i18n.locale}/book`">{{
                $t("nav.book")
              }}</router-link>
            </li>
          </ul>
        </div>
        <div class="col-6 text-right nav-responsive">
          <ul
            class="navlist navr"
            v-bind:class="[isWhiteClass ? 'white' : 'black']"
          >
            <li
              @click="hambEvent"
              class="hamb navw"
              v-bind:class="{ none: isNoneClass1 }"
            >
              <img src="@/assets/img/menu_white.svg" />
            </li>
            <li
              @click="hambEvent"
              class="hamb navb"
              v-bind:class="{ none: !isNoneClass1 }"
            >
              <img src="@/assets/img/menu_black.svg" />
            </li>
            <li
              class="menu-close"
              @click="menuEvent"
              v-bind:class="{ none: isNoneClass }"
            >
              <img src="@/assets/img/menu_close.svg" />
            </li>
          </ul>
        </div>
        <div
          class="logow"
          v-bind:class="{
            toplogo: isTopLogoClass,
            none: isNoneClass1,
          }"
        >
          <router-link :to="`/`"
            ><img src="@/assets/img/logo-white.svg"
          /></router-link>
        </div>
        <div
          class="logob"
          v-bind:class="{
            none: !isNoneClass1,
          }"
        >
          <router-link :to="`/`"
            ><img src="@/assets/img/logo-black.svg"
          /></router-link>
        </div>
      </div>
      <div class="row menu" v-bind:class="{ nav_open: isNavOpenClass }">
        <div class="col-6">
          <ul
            class="navlist navl"
            v-bind:class="[isWhiteClass ? 'white' : 'black']"
          >
            <li>
              <router-link
                :to="{
                  name: 'Home',
                  hash: '#info',
                }"
                >info</router-link
              >
            </li>
            <li>
              <router-link
                :to="{
                  name: 'Home',
                  hash: '#who',
                }"
                >{{ $t("nav.who") }}</router-link
              >
            </li>
            <li>
              <router-link
                :to="{
                  name: 'Home',
                  hash: '#why',
                }"
                >{{ $t("nav.why") }}</router-link
              >
            </li>
          </ul>
        </div>
        <div class="col-6 text-right">
          <ul
            class="navlist navr"
            v-bind:class="[isWhiteClass ? 'white' : 'black']"
          >
            <li>
              <router-link :to="`/${$i18n.locale}/book`">{{
                $t("nav.book")
              }}</router-link>
            </li>
            <li>
              <router-link :to="`/${$i18n.locale}/menu`">{{
                $t("nav.menu")
              }}</router-link>
            </li>
            <li>
              <router-link :to="`/${$i18n.locale}/practical`">{{
                $t("nav.practical")
              }}</router-link>
            </li>
            <li>
              <a href="#" @click.prevent="setLocale()" ref="account">{{
                $t("nav.language")
              }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="outer"
    id="header"
    v-bind:class="{ padding: isHeaderClass }"
  >
    <div class="container-fluid">
      <div class="row">
        <div class="col-6 nav-responsive">
          <ul class="navlist navl black">
            <li>
              <router-link :to="`/${$i18n.locale}/book`">{{
                $t("nav.book")
              }}</router-link>
            </li>
          </ul>
        </div>
        <div class="col-6 text-right nav-responsive">
          <ul class="navlist navr black">
            <li @click="hambEvent" class="hamb navw none">
              <img src="@/assets/img/menu_white.svg" />
            </li>
            <li @click="hambEvent" class="hamb navb">
              <img src="@/assets/img/menu_black.svg" />
            </li>
            <li
              class="menu-close"
              @click="menuEvent"
              v-bind:class="{ none: isNoneClass }"
            >
              <img src="@/assets/img/menu_close.svg" />
            </li>
          </ul>
        </div>
        <div
          class="logow none"
          v-bind:class="{
            toplogo: isTopLogoClass,
          }"
        >
          <router-link :to="`/`"
            ><img src="@/assets/img/logo-white.svg"
          /></router-link>
        </div>
        <div
          class="logob"
          v-bind:class="{
            none: !isNoneClass1,
          }"
        >
          <router-link :to="`/`"
            ><img src="@/assets/img/logo-black.svg"
          /></router-link>
        </div>
      </div>
      <div class="row menu" v-bind:class="{ nav_open: isNavOpenClass }">
        <div class="col-6">
          <ul class="navlist navl black">
            <li>
              <router-link
                :to="{
                  name: 'Home',
                  hash: '#info',
                }"
                >info</router-link
              >
            </li>
            <li>
              <router-link
                :to="{
                  name: 'Home',
                  hash: '#who',
                }"
                >{{ $t("nav.who") }}</router-link
              >
            </li>
            <li>
              <router-link
                :to="{
                  name: 'Home',
                  hash: '#why',
                }"
                >{{ $t("nav.why") }}</router-link
              >
            </li>
          </ul>
        </div>
        <div class="col-6 text-right">
          <ul class="navlist navr black">
            <li>
              <router-link :to="`/${$i18n.locale}/book`">{{
                $t("nav.book")
              }}</router-link>
            </li>
            <li>
              <router-link :to="`/${$i18n.locale}/menu`">{{
                $t("nav.menu")
              }}</router-link>
            </li>
            <li>
              <router-link :to="`/${$i18n.locale}/practical`">{{
                $t("nav.practical")
              }}</router-link>
            </li>
            <li>
              <a href="#" @click.prevent="setLocale()">{{
                $t("nav.language")
              }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isWhiteClass: true,
      isHidden: false,
      isHeaderClass: false,
      isNoneClass: true,
      isNoneClass1: false,
      isNavOpenClass: false,
      isTopLogoClass: false,
    };
  },
  props: ["isWhite"],
  name: "Header",
  methods: {
    setLocale: function () {
      let lang = event.currentTarget.innerHTML;
      this.$i18n.locale = lang;
      if (
        this.$router.currentRoute.path == "/en/" ||
        this.$router.currentRoute.path == "/nl/" ||
        this.$router.currentRoute.path == "/en" ||
        this.$router.currentRoute.path == "/nl"
      ) {
        this.$router.push({ path: `/${lang}` });
      } else {
        this.$router.push({
          params: { lang: lang },
        });
      }
    },
    showMenu: function () {
      if (this.isHidden) {
        this.isHidden = false;
        this.isWhiteClass = false;
        this.isNoneClass1 = true;
      }
    },
    hideMenu: function () {
      if (!this.isHidden) {
        this.isHidden = true;
        this.isWhiteClass = true;
        this.isNoneClass1 = false;
      }
    },
    hambEvent: function () {
      this.isNoneClass = false;
      this.isNavOpenClass = this.isTopLogoClass = true;
    },
    handleScroll: function () {
      let scrollTop = window.pageYOffset;
      if (this.$router.currentRoute.path.length > 3 && !this.isRouted) {
        if (scrollTop > 100) {
          this.isHeaderClass = true;
        } else {
          this.isHeaderClass = false;
        }
      } else {
        let spacers = document.querySelectorAll(".switch");
        let flag = false;
        spacers.forEach(function (spacer) {
          let rect = spacer.getBoundingClientRect();
          let offset = {
            top: rect.top - 40 + window.scrollY,
          };
          if (offset.top < scrollTop && offset.top + rect.height > scrollTop) {
            flag = true;
            return false;
          }
        });

        if (flag) {
          this.showMenu();
        } else {
          this.hideMenu();
        }
        if (scrollTop > 100) {
          this.isHeaderClass = true;
        } else {
          this.isHeaderClass = false;
        }
      }
    },
    menuEvent: function (event) {
      event.preventDefault();
      this.isNoneClass = true;
      this.isNavOpenClass = this.isTopLogoClass = false;
      let target = event.target;
      let href = target.getAttribute("href");
      if (
        this.$router.currentRoute.path.length > 3 &&
        target.getAttribute("href") != "#" &&
        target.getAttribute("class") != null
      ) {
        let locale = this.$i18n.locale;
        this.$router.push({ path: `/${locale}` });
      } else if (target.tagName === "A" && target.getAttribute("href") != "#") {
        this.scrollIt(document.querySelector(href), 1500);
      }
    },
    scrollIt: function (element) {
      window.scrollTo({
        behavior: "smooth",
        left: 0,
        top: element.offsetTop,
      });
    },
  },
  mounted() {
    if (this.isWhite) {
      this.hideMenu();
    } else {
      this.showMenu();
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
