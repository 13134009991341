<template>
  <div class="home">
    <Header v-bind:isWhite="false" />
    <BodyPractical />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import BodyPractical from "@/components/BodyPractical.vue";

export default {
  name: "Home",
  components: {
    Header,
    Footer,
    BodyPractical,
  },
};
</script>

