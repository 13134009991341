import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "@/assets/css/main.css";
import "@/assets/css/cookie.css";
import ScrollAnimation from "@/directives/scrollanimation.js";
Vue.directive("scrollanimation", ScrollAnimation);
import i18n from "./i18n";

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  let lang = to.params.lang;
  Vue.prototype.$appName = getCookie("purecookieDismiss");
  if (!lang) {
    lang = "en";
  }
  i18n.locale = lang;
  next();
});

function getCookie(name) {
  const regex = RegExp("^" + name + "*");
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    if (regex.test(ca[i].trim())) {
      return true;
    }
  }
  return false;
}

new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount("#app");
