<template>
  <div id="kennismaking" class="index outer bggrey switch">
    <div class="container-fluid" id="who">
      <div class="row padding150 align-items-center">
        <div
          class="col-md-10 offset-md-1 col-lg-6 offset-lg-0"
          v-scrollanimation
        >
          <img src="@/assets/img/Sander 2024.jpeg" class="img-fluid" />
        </div>

        <div class="col-md-10 offset-md-1 col-lg-5" v-scrollanimation>
          <div class="negmargin">
            <h3>{{ $t("home.introduction.title") }}</h3>
            <p>
              {{ $t("home.introduction.content") }}
            </p>
            <ul>
              <li>{{ $t("home.introduction.list.one") }}</li>
              <li>{{ $t("home.introduction.list.two") }}</li>
              <li>{{ $t("home.introduction.list.three") }}</li>
              <li>{{ $t("home.introduction.list.four") }}</li>
            </ul>
            <p>
              {{ $t("home.introduction.content2") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Atmosphere",
};
</script>
