<template>
  <div class="index outer bgblack" id="restaurantmodest">
    <div class="container-fluid" id="info">
      <div class="row padding215 align-items-center">
        <div
          class="col-md-10 offset-md-1 col-lg-6 offset-lg-0"
          v-scrollanimation
        >
          <h2>Restaurant Modest</h2>
        </div>
        <div class="col-md-10 offset-md-1 col-lg-5" v-scrollanimation>
          <div class="negmargin">
            {{ $t("home.modest.content") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Concept",
};
</script>
