<template>
  <div class="index outer bgblack" id="waarommodest">
    <div class="container-fluid">
      <div class="row padding215 align-items-center">
        <div
          class="col-md-10 offset-md-1 col-lg-6 offset-lg-0"
          v-scrollanimation
        >
          <h2 id="why">{{ $t("home.why.title") }}</h2>
        </div>
        <div class="col-md-10 offset-md-1 col-lg-5" v-scrollanimation>
          <div class="negmargin">
            <p>
              {{ $t("home.why.content") }}
            </p>
          </div>
        </div>
      </div>      
    </div>
  </div>
</template>
<script>
export default {
  name: "Food",
};
</script>
