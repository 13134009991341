<template>
  <div
    class="cookieConsentContainer"
    id="cookieConsentContainer"
    style="opacity: 1; display: block;"
  >
    <div class="cookieDesc">
      <p>
        This website uses cookies.
        <a href="#hiermoetprivacypolicy">More info</a
        ><a @click="purecookieDismiss" class="cookieButton">Accept</a>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "CookieConsent",
  methods: {
    purecookieDismiss: function() {
      this.setCookie("purecookieDismiss", "1", 7);
      this.pureFadeOut("cookieConsentContainer");
    },
    pureFadeIn: function(elem, display) {
      var el = document.getElementById(elem);
      el.style.opacity = 0;
      el.style.display = display || "block";

      (function fade() {
        var val = parseFloat(el.style.opacity);
        if (!((val += 0.02) > 1)) {
          el.style.opacity = val;
          requestAnimationFrame(fade);
        }
      })();
    },
    pureFadeOut: function(elem) {
      let el = document.getElementById(elem);
      el.style.opacity = 1;

      (function fade() {
        if ((el.style.opacity -= 0.02) < 0) {
          el.style.display = "none";
        } else {
          requestAnimationFrame(fade);
        }
      })();
    },
    setCookie: function(name, value, days) {
      let expires = "";
      if (days) {
        let date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }
  }
};
</script>
