<template>
  <div class="outer sub" id="wrapper">
    <div class="container-fluid">
      <div class="row padding260">
        <div class="col-md-1 col-lg-1">
        </div>
        <div class="col-md-4 col-lg-4">
          <h2>{{ $t("book.title") }}</h2>
          <p>{{ $t("book.content1") }}</p>
          <br/>
          <p>{{ $t("book.content2") }}</p>
          <br/>
          <p>{{ $t("book.content3") }}</p>
        </div>
        <div class="col-md-1 col-lg-1">
        </div>
        <div class="col-md-4 col-lg-4">
          <div id="resengo-booknow"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "BodyBook",
  mounted() {

    let tryAndLoad = function() {
        if(window.bookNow){
          window.bookNow({
              companyID: '1771021',
                target: '#resengo-booknow',
                language: locale
            });
          } else {
            window.setTimeout(tryAndLoad, 1000);
          }
        };

    let locale = this.$i18n.locale;
    if(!window.bookNow){
      window.setTimeout(tryAndLoad, 1000);
    } else {
        window.bookNow({
            companyID: '1771021',
              target: '#resengo-booknow',
              language: locale
          });

    }
  },
};
</script>
