<template>
  <div id="app">
    <router-view />
    <CookieConsent v-if="!this.$appName" />
  </div>
</template>

<script>
import CookieConsent from "@/components/CookieConsent.vue";
export default {
  components: {
    CookieConsent
  }
};
</script>

<style>
html {
  scroll-behavior: smooth;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
