<template>
  <div class="home">
    <Header v-bind:isWhite="false" />
    <BodyMenu />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import BodyMenu from "@/components/BodyMenu.vue";
export default {
  name: "Home",
  components: {
    Header,
    Footer,
    BodyMenu,
  },
};
</script>
 
