<template>
  <div class="book">
    <Header v-bind:isWhite="false" />
    <BodyBook />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import BodyBook from "@/components/BodyBook.vue";

export default {
  name: "Book",
  components: {
    Header,
    Footer,
    BodyBook,
  },
};
</script>
