<template>
  <div>
    <a href="https://goo.gl/maps/tJzHGUiinMRedMe6A" target="_blank">
      <!-- <img
        src="@/assets/admin/resources/img-practical.png"
        class="img-fluid d-md-none mob-map"
        data-aos="fade-up"
        data-aos-duration="750"
        data-aos-delay="250"
    /> -->
    </a>

    <div class="outer sub">
      <div class="container-fluid">
        <div class="row align-items-center" style="padding-top: 150px;">
          <div
            class="col-md-4 offset-md-1 col-lg-4 offset-lg-1"
            data-aos="fade-up"
            data-aos-duration="750"
          >
            <div class="practical">
              <h2>{{ $t("practical.location") }}</h2>
              <p>Weg naar Opoeteren 117</p>
              <p>3660 Opglabbeek (Oudsbergen)</p>
              <br/>
              <p>
                <a
                  href="https://goo.gl/maps/rfC87TDGNiojHvpG8"
                  target="_blank"
                  >{{ $t("practical.directions.map") }}</a></p>
            </div>
            <div class="practical">
              <h2>{{ $t("practical.hours.title") }}</h2>
              <p>{{ $t("practical.hours.days") }}</p>
              <p>{{ $t("practical.hours.lunch") }}</p>
              <p>{{ $t("practical.hours.dinner") }}<p>
              <p>{{ $t("practical.hours.closed")}}</p>
            </div>

            <div class="practical reservation">
              <h2>Contact</h2>
              <p>
                <a href="email:info@restaurantmodest.be">info@restaurantmodest.be</a><br />
              </p>
              <p>{{ $t("practical.contact.call") }}
                <a href="tel:089/216025">089/216020</a><br />
              </p>
            </div>
          </div>
          <div
            class="order-md-2 d-none d-md-block col-md-6 col-lg-5 offset-lg-1"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-delay="250"
          >
            <a href="https://goo.gl/maps/tJzHGUiinMRedMe6A" target="_blank">
              <!-- <img
                src="@/assets/admin/resources/img-practical.png"
                class="img-fluid"
                alt="© Google"
            /> -->
            </a>
          </div>

          <div
            class="col-md-5 offset-md-1 col-lg-5 offset-lg-1"
            data-aos="fade-up"
            data-aos-duration="750"
          >
            <img src="@/assets/img/Modestmenujuli-8.jpeg" class="img-fluid" />
            <p></br></p>
            <img src="@/assets/img/Modestmenujuni-10.jpeg" class="img-fluid" />
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BodyPractical",
};
</script>
