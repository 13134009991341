import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Book from "../views/Book.vue";
import Menu from "../views/Menu.vue";
import Practical from "../views/Practical.vue";
import i18n from "../i18n.js";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: `/${i18n.locale}`
  },
  {
    path: "/:lang",
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
  {
    path: "/",
    name: "Home",
    component: Home
  },  
      {
        path: "book",
        name: "Book",
        component: Book
      },
      {
        path: "menu",
        name: "Menu",
        component: Menu
      },
      {
        path: "practical",
        name: "Practical",
        component: Practical
      }
  ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to) {
    const posistion = {};
    if (to.hash) {
      posistion.selector = to.hash;
      if (document.querySelector(to.hash)) {
        return posistion;
      }
      return false;
    }
    return {
      x: 0,
      y: 0
    };
  },
  routes
});

export default router;
